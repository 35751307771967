import React, { useState, useEffect, useContext } from "react";
import DisplayHorizontal from "./ads/DisplayHorizontal";
import InArticle from "./ads/InArticle";
import LikeButton from "./buttons/LikeButton";
import { useQuery, useQueryClient } from "react-query";
import { AuthContext } from "./users/AuthContext";
import axios from "axios";
import { SettingsContext } from "./SettingsContext";

function ChapterContent ( { bookDetails, bookIDSlug, chapterDetails, nextBook } ) {
    const {chapter_id,
        book_id,
        title,
        chapter_word_count,
        content,
        previous_chapter_id,
        next_chapter_id,
        footnote,
        chapter_author_note,
        chapter_tl_note,
        uploaded_time,
        last_updated_time,
    } = chapterDetails;

    const [previousChapterId, setPreviousChapterId] = useState(null);
    const [nextChapterId, setNextChapterId] = useState(null);

    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupCallback, setPopupCallback] = useState(null);
    const [showLogin, setShowLogin] = useState(false);

    const [liked, setLiked] = useState(null);
    const [likeCount, setLikeCount] = useState(null);
    const [isLikeSubmitting, setIsLikeSubmitting] = useState(false);

    const queryClient = useQueryClient();
    const { user, refreshToken } = useContext(AuthContext);
    const { fontSize, lineHeight, pageSpace } = useContext(SettingsContext);

    useEffect(() => {
        queryClient.invalidateQueries(['like-books', book_id]);
    }, [queryClient]);

    const fetchBookLike = async () => {
        if (user) {
            await refreshToken();
        }
        
        const endpoint = user 
        ? `${process.env.REACT_APP_BASE_URL}/api/like/show-user-like-count` 
        : `${process.env.REACT_APP_BASE_URL}/api/like/show-visitor-like-count`;
        
        const response = await axios.get(endpoint, {
            params: {book_id}
        });
    
        setLiked(response.data.isLiked);
        setLikeCount(response.data.likeCount.like_count);
        return response.data;
    };

    const { data, error, isLoading } = useQuery(['like-books', book_id], fetchBookLike, {
        refetchOnWindowFocus: false,
    });

    const handleLike = async () => {
        setIsLikeSubmitting(true);
        if (!user) {
            setPopupMessage('You must log in to like a story.');
            setPopupCallback(null);
            setShowLogin(true);
            setShowPopup(true);
            setTimeout(() => setIsLikeSubmitting(false), 500); // Re-enable the button after 0.5 second
            return;
        }

        try {
            await refreshToken();
            const endpoint = liked
                ? `${process.env.REACT_APP_BASE_URL}/api/like/remove-like-book`
                : `${process.env.REACT_APP_BASE_URL}/api/like/like-book`;
            await axios.post(endpoint, { book_id: book_id });

            setLiked(!liked);
            setLikeCount(liked ? likeCount - 1 : likeCount + 1);
        } catch (error) {
            console.error('Error liking/unliking story:', error);
            if (error.response && error.response.status === 403) {
                setPopupMessage(error.response.data.message);
                setPopupCallback(null);
                setShowPopup(true);
            }
        } finally {
            setTimeout(() => setIsLikeSubmitting(false), 500); // Re-enable the button after 0.5 second
        }
    };


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event) => {
        // Check if the user is focused on an input or textarea element
        const activeElement = document.activeElement;
        if (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.isContentEditable) {
            return; // Do nothing if focus is on an input or textarea
        }

        // Check if the ALT key is being held down
        if (event.altKey) {
            return; // Do nothing if the ALT key is held down
        }

        if (event.key === 'ArrowLeft' && previous_chapter_id) {
            window.location.href = `/${bookIDSlug}/${previous_chapter_id}`;
        } else if (event.key === 'ArrowRight' && next_chapter_id) {
            window.location.href = `/${bookIDSlug}/${next_chapter_id}`;
        }
    };

    const processContent = (content) => {
        if (!content) return [];

        // Split the content by line breaks and filter out empty lines
        const parts = content.split('\n').filter(part => part.trim() !== '');

        // // Create an array to hold the React elements
        // const elements = [];

        // parts.forEach((part, index) => {
        //     // Add the current part
        //     elements.push(<span key={`part-${index}`}>{part}</span>);

        //     // Add a line break after each part except the last one
        //     if (index < parts.length - 1) {
        //         elements.push(<br key={`br1-${index}`} />);
        //         elements.push(<br key={`br2-${index}`} />);
        //     }

        //     // Insert Ads
        //     if (index === 5 || index === 25 || index === 45 || index === 65) {
        //         elements.push(<DisplayHorizontal key={`ad-${index}`} />);
        //     }
        // });

        // Create an array to hold the React elements
        const elements = [];

        let cumulativeWordCount = 0;
        const targetWordCounts = [50, 200, 400, 600, 800];
        let targetIndex = 0;

        parts.forEach((part, index) => {
            part = part.trim();

            const wordCount = part.split(/\s+/).length;
            cumulativeWordCount += wordCount;

            // Add the current part
            elements.push(<span key={`part-${index}`}>{part}</span>);

            // Add a line break after each part except the last one
            if (index < parts.length - 1) {
                elements.push(<br key={`br1-${index}`} />);

                // Check if the current cumulative word count has reached the next target
                if (targetIndex < targetWordCounts.length && cumulativeWordCount >= targetWordCounts[targetIndex]) {
                    elements.push(<DisplayHorizontal key={`ad-${index}`} />);
                    targetIndex++; // Move to the next target
                } else {
                    elements.push(<br key={`br2-${index}`} />);
                }
            }
        });

        return elements;
    };

    const formattedContent = processContent(content);
    // const formattedContent = content ? content.replace(/\n/g, '<br/>') : '';
    const formattedFootnote = footnote ? footnote.replace(/\n/g, '<br/>') : '';
    const formattedAuthorNote = chapter_author_note ? chapter_author_note.replace(/\n/g, '<br/>') : '';
    const formattedTLNote = chapter_tl_note ? chapter_tl_note.replace(/\n/g, '<br/>') : '';

    let nextBookIDSlug = null;

    // Process next book data if exist
    if (nextBook) {
        // Create slug with the format of bookID-bookName
        // 1. Combine the ID and Name with a hyphen, ensuring there's a space around the hyphen if not already present
        nextBookIDSlug = `${nextBook.book_id} - ${nextBook.english_book_name}`;

        // 2. Remove Unwanted Characters (keep only alphanumeric and spaces)
        nextBookIDSlug = nextBookIDSlug.replace(/[^a-zA-Z0-9 -]/g, "");

        // 3. Replace Spaces and multiple hyphens with a single Hyphen
        nextBookIDSlug = nextBookIDSlug.replace(/[\s-]+/g, '-');

        // 4. Convert to Lowercase
        nextBookIDSlug = nextBookIDSlug.toLowerCase();
    }

    const handlePopupClose = () => {
        if(showLogin == true) {
            setShowPopup(false);
            setShowLogin(false);
        } else {
            setShowPopup(false);
        }
    }
    
    const handlePopupLogin = () => {
        if(showLogin == true) {
            setShowPopup(false);
            setShowLogin(false);
            window.open('/user/login', '_blank');
        } else {
            setShowPopup(false);
        }
    }

    return (
        <div className={`flex flex-col gap-4 py-5 px-3 lg:px-6 w-full`}>
            <div className={`flex flex-col py-5 justify-center items-center border-b-2 border-tertiary`}>
                <a href={`/${bookIDSlug}`} className="mb-5 text-3xl font-bold text-center break-words hover:underline">
                    {bookDetails.english_book_name}
                </a>
                <h2 className="text-2xl font-semibold text-center break-words">{title}</h2>
                <p className="text-sm text-center">{chapter_word_count} words</p>
            </div>

            {/* <div className="flex flex-row justify-center items-center">
                <button
                    onClick={decreaseFontSize}
                    disabled={fontSizeIndex === 0}
                    className="mr-2 bg-gray-200 p-4 rounded-full disabled:opacity-50"
                >
                    <img src={decrease_font_size_reference} loading="lazy" alt="Decrease Font Size Icon" />
                </button>

                <button
                    onClick={increaseFontSize}
                    disabled={fontSizeIndex === fontSizes.length - 1}
                    className="bg-gray-200 p-4 rounded-full disabled:opacity-50"
                >
                    <img src={increase_font_size_reference} loading="lazy" alt="Increase Font Size Icon" />
                </button>
            </div> */}

            <div className="p-2 break-words w-full">
                {/* <p className=" text-lg text-justify leading-10" dangerouslySetInnerHTML={{ __html: formattedContent }}></p> */}
                <div className={` ${fontSize} text-justify ${lineHeight}`}>{formattedContent}</div>
            </div>

            {!footnote ? "" : 
                <div className={`flex flex-col p-2 border-t-2 border-tertiary break-words ${fontSize} text-justify w-full`}>
                    <p className="underline">Footnote:</p>
                    <p dangerouslySetInnerHTML={{ __html: formattedFootnote }}></p>
                </div>
            }

            {!chapter_author_note ? "" : 
                <div className={`flex flex-col p-2 border-t-2 border-tertiary break-words ${fontSize} text-justify w-full`}>
                    <p className="underline">Author Note:</p>
                    <p dangerouslySetInnerHTML={{ __html: formattedAuthorNote }}></p>
                </div>
            }

            {!chapter_tl_note ? "" : 
                <div className={`flex flex-col p-2 border-t-2 border-tertiary break-words ${fontSize} text-justify w-full`}>
                    <p className="underline">TL Note:</p>
                    <p dangerouslySetInnerHTML={{ __html: formattedTLNote }}></p>
                </div>
            }

            {!isLoading && 
                <div className="flex flex-col justify-center items-center gap-2">
                    <LikeButton
                        liked={liked}
                        onClick={handleLike}
                        size={window.innerWidth >= 1024 ? '30' : '25'} // Adjust the size as needed
                        isSubmitting={isLikeSubmitting}
                    />
                    <span>{likeCount > 0 ? `${likeCount} people like this story!` : `Be the first to like this story!`}</span>
                </div>
            }

            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-4 rounded shadow-lg text-center text-tertiary">
                        <p>{popupMessage}</p>
                        {popupCallback ? (
                        <div className="mt-4">
                            <button onClick={popupCallback} className="bg-red-500 text-white px-4 py-2 rounded mr-2">Yes</button>
                            <button onClick={() => setShowPopup(false)} className="bg-gray-500 text-white px-4 py-2 rounded">No</button>
                        </div>
                        ) : (
                            <div className='flex justify-around'>
                                {(showLogin &&
                                    <button onClick={handlePopupLogin} className="bg-red-400 text-white py-2 px-4 rounded-md mt-4 hover:bg-tertiary transition duration-300">
                                    Log In
                                    </button>
                                )}
                                <button onClick={handlePopupClose} className="bg-gray-400 text-white py-2 px-4 rounded-md mt-4 hover:bg-tertiary transition duration-300">
                                    Close
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="flex flex-col gap-3">
                <div className={`flex justify-between gap-5 w-full`}>
                    {previous_chapter_id ? (
                        <div className={`flex border-2 border-tertiary p-2`}>
                            <a href={`/${bookIDSlug}/${previous_chapter_id}`} className="flex justify-center items-center hover:underline text-md lg:text-lg text-center">
                                Previous Chapter
                            </a>
                        </div>
                    ) : (
                        <div className="invisible">Previous Chapter</div>
                    )}
                    <div className={`flex border-2 border-tertiary p-2`}>
                        <a href={`/${bookIDSlug}`} className="flex justify-center items-center hover:underline text-md lg:text-lg text-center">
                            Index
                        </a>
                    </div>
                
                    {next_chapter_id ? (
                        <div className={`flex border-2 border-tertiary p-2`}>
                            <a href={`/${bookIDSlug}/${next_chapter_id}`} className="flex justify-center items-center hover:underline text-md lg:text-lg text-center">
                                Next Chapter
                            </a>
                        </div>
                    ) : (
                        <div className="invisible">Next Chapter</div>
                    )}
                </div>
                <div className="flex justify-center items-center">
                    {!next_chapter_id &&
                        <div className={`flex border-2 border-tertiary p-2`}>
                            <a href={`/${nextBookIDSlug}`} className="flex justify-center items-center hover:underline text-md lg:text-lg text-center break-words">
                                Next: {nextBook.english_book_name}
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ChapterContent;